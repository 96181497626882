import { useCallback, useContext } from 'react';

import { match, P } from 'ts-pattern';

import { useAnalyticsEvents } from '@atlaskit/analytics-next';

import { AIEventsInstrumentationContext } from '../context';
import {
	type AIEventErrorAttributes,
	type AIEventPayload,
	type AIFeedbackResultType,
	AISessionState,
	type InternalAIEvent,
	type OverrideAIEventPayload,
} from '../events';

export const useAIEventsInstrumentation = () => {
	const context = useContext(AIEventsInstrumentationContext);

	const { createAnalyticsEvent } = useAnalyticsEvents();

	if (context === null) {
		throw new Error(
			'useAIEventsInstrumentation must be used within a AIEventsInstrumentationProvider',
		);
	}

	const {
		config: {
			source,
			aiFeatureName,
			proactiveGeneratedAI,
			userGeneratedAI,
			isAIFeature,
			channel,
			subproduct,
			actionSubjectId,
			tags,
		},
		refreshSingleInstrumentationID,
		getSingleInstrumentationID,
		setAISessionState,
		getAISessionState,
	} = context;

	const validateAndUpdateAISession = useCallback(
		(nextState: AISessionState) => {
			const currentAISessionState = getAISessionState();

			match({ currentState: currentAISessionState, nextState })
				.with(
					{
						currentState: AISessionState.unset,
						nextState: P.union(
							AISessionState.viewed,
							AISessionState.error,
							AISessionState.dismissed,
						),
					},
					() => {
						throw new Error(
							`[@atlassian/ai-analytics]: ${nextState} event should be called only after trackAIInteractionInit()`,
						);
					},
				)
				.with(
					{
						currentState: AISessionState.initiated,
						nextState: P.union(AISessionState.submitted, AISessionState.actioned),
					},
					() => {
						throw new Error(
							`[@atlassian/ai-analytics]: ${nextState} event should be called only after trackAIResultView() OR trackAIResultError() OR trackAIInteractionDismiss() event`,
						);
					},
				)
				.otherwise(() => setAISessionState(nextState));
		},
		[setAISessionState, getAISessionState],
	);

	const fireEventWithOptionalChannel = useCallback(
		(event: InternalAIEvent) => {
			if (channel) {
				createAnalyticsEvent(event).fire(channel);
			} else {
				createAnalyticsEvent(event).fire();
			}
		},
		[channel, createAnalyticsEvent],
	);

	const createAndMergeEventPayload = useCallback(
		(
			payload: OverrideAIEventPayload,
			partialPayload?: Partial<AIEventPayload>,
		): InternalAIEvent => {
			const singleInstrumentationID = getSingleInstrumentationID();
			return {
				type: 'sendTrackEvent',
				data: {
					action: payload.action,
					actionSubject: payload.actionSubject,
					tags: partialPayload?.tags ?? tags,
					actionSubjectId: partialPayload?.actionSubjectId ?? actionSubjectId,
					source: partialPayload?.source ?? source,
					subproduct,
					attributes: {
						aiFeatureName,
						proactiveGeneratedAI,
						userGeneratedAI,
						isAIFeature,
						singleInstrumentationID,
						...payload.attributes,
						...partialPayload?.attributes,
					},
				},
			};
		},
		[
			subproduct,
			aiFeatureName,
			proactiveGeneratedAI,
			getSingleInstrumentationID,
			userGeneratedAI,
			isAIFeature,
			source,
			tags,
			actionSubjectId,
		],
	);

	const trackAIInteractionInit = useCallback(
		(payload?: Partial<AIEventPayload>) => {
			refreshSingleInstrumentationID();
			validateAndUpdateAISession(AISessionState.initiated);
			const event = createAndMergeEventPayload(
				{
					action: 'initiated',
					actionSubject: 'aiInteraction',
				},
				payload,
			);

			fireEventWithOptionalChannel(event);
		},
		[
			refreshSingleInstrumentationID,
			createAndMergeEventPayload,
			fireEventWithOptionalChannel,
			validateAndUpdateAISession,
		],
	);

	const trackAIResultView = useCallback(
		(payload?: Partial<AIEventPayload>) => {
			validateAndUpdateAISession(AISessionState.viewed);
			const event = createAndMergeEventPayload(
				{
					action: 'viewed',
					actionSubject: 'aiResult',
				},
				payload,
			);

			fireEventWithOptionalChannel(event);
		},
		[validateAndUpdateAISession, createAndMergeEventPayload, fireEventWithOptionalChannel],
	);

	const trackAIResultError = useCallback(
		(error: Required<AIEventErrorAttributes>, payload?: Partial<AIEventPayload>) => {
			validateAndUpdateAISession(AISessionState.error);
			const event = createAndMergeEventPayload(
				{
					action: 'error',
					actionSubject: 'aiResult',
					attributes: {
						...error,
					},
				},
				payload,
			);

			fireEventWithOptionalChannel(event);
		},
		[validateAndUpdateAISession, createAndMergeEventPayload, fireEventWithOptionalChannel],
	);

	const trackAIInteractionDismiss = useCallback(
		(payload?: Partial<AIEventPayload>) => {
			validateAndUpdateAISession(AISessionState.dismissed);
			const event = createAndMergeEventPayload(
				{
					action: 'dismissed',
					actionSubject: 'aiInteraction',
				},
				payload,
			);

			fireEventWithOptionalChannel(event);
		},
		[validateAndUpdateAISession, createAndMergeEventPayload, fireEventWithOptionalChannel],
	);

	const trackAIResultAction = useCallback(
		(aiResultAction: string, payload?: Partial<AIEventPayload>) => {
			validateAndUpdateAISession(AISessionState.actioned);
			const event = createAndMergeEventPayload(
				{
					action: 'actioned',
					actionSubject: 'aiResult',
					attributes: {
						aiResultAction,
					},
				},
				payload,
			);

			fireEventWithOptionalChannel(event);
		},
		[validateAndUpdateAISession, createAndMergeEventPayload, fireEventWithOptionalChannel],
	);

	const trackAIFeedbackSubmit = useCallback(
		(aiFeedbackResult: AIFeedbackResultType, payload?: Partial<AIEventPayload>) => {
			validateAndUpdateAISession(AISessionState.submitted);
			const event = createAndMergeEventPayload(
				{
					action: 'submitted',
					actionSubject: 'aiFeedback',
					attributes: {
						aiFeedbackResult,
					},
				},
				payload,
			);

			fireEventWithOptionalChannel(event);
		},
		[validateAndUpdateAISession, createAndMergeEventPayload, fireEventWithOptionalChannel],
	);

	return {
		trackAIInteractionInit,
		trackAIResultView,
		trackAIResultError,
		trackAIInteractionDismiss,
		trackAIResultAction,
		trackAIFeedbackSubmit,
		refreshSingleInstrumentationID,
	};
};
