import { createContext } from 'react';

import type { AIEventBaseAttributes, AIEventBasePayload, AISessionState } from '../events';

export const AIEventsInstrumentationContext =
	createContext<AIEventsInstrumentationContextType>(null);

type AIAnalyticsConfig = Partial<{
	channel: string;
}>;

export type AIEventsInstrumentationConfig = AIEventBasePayload &
	AIEventBaseAttributes &
	AIAnalyticsConfig;

export type AIEventsInstrumentationContextType = {
	config: AIEventsInstrumentationConfig;
	refreshSingleInstrumentationID: () => void;
	getSingleInstrumentationID: () => string | undefined;
	setAISessionState: (nextState: AISessionState) => void;
	getAISessionState: () => AISessionState;
} | null;
